.contact-form{
    padding: 0 3rem 0 3rem;
    display: flex;
    height: 88vh;
    margin-top: 8rem;

}

.c-right{
    display: flex;
    justify-content: center;
    position: relative;
    flex: 1;
}

.c-right>form{
    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-items: center;
}

.c-right .user{
    width: 20rem;
    height: 2rem;
    margin-top: 1rem;
    padding: 0.3rem;
    outline: none;
    border: 2px solid var(--orange);
    border-radius: 8px;
    font-size: 16px;
}

.c-right>form>span{
    color: var(--orange);
    padding-top: 2rem;

}


textarea{
    height: 4rem!important;
}

.c-blur1{
    top: 1rem;
    left: 8rem;
}


















